import { RMText } from '../RMText/RMText';

import { Progress, Step, StepBar, Stepper } from './RMStepper.styles';

export interface RMStepperItem {
  label: string;
  disabled?: boolean;
}

interface RMStepperProps {
  activeStepIndex: number;
  steps: RMStepperItem[];
  layout?: 'stepper' | 'progress';
  hideIndex?: boolean;
  onStepChange?: (stepIndex: number) => void;
}

export function RMStepper({ activeStepIndex, steps, layout, hideIndex = false, onStepChange }: RMStepperProps) {
  if (layout === 'stepper') {
    return (
      <Stepper>
        {steps.map((step, index) => (
          <Step key={step.label} data-disabled={step.disabled} onClick={() => !step.disabled && onStepChange?.(index)}>
            <RMText
              type="sans"
              size="xxs"
              bold
              trackedOutCaps
              truncate
              color={index === activeStepIndex ? 'on-surface-secondary' : 'on-surface-tertiary'}
            >
              {hideIndex == true ? '' : `${index + 1} `}
              {step.label}
            </RMText>

            <StepBar data-disabled={step.disabled} active={index === activeStepIndex} />
          </Step>
        ))}
      </Stepper>
    );
  }

  return <Progress value={activeStepIndex + 1} max={steps.length} />;
}
